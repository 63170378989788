/* eslint-disable no-unused-vars */

import React, { useEffect, useRef } from 'react';
import propTypes, { number } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { numberWithCommas } from '../../../misc/misc';

const PaymentSettingDisplay = ({ user, propertyInfo, paymentInfo, handlePaymentSelect, showSubscriptionPlans, setShowSubscriptionPlans, paymentType = "owned" }) => {
  const { t } = useTranslation();

  // eslint-disable-next-line camelcase
  // const { property_type, street_address, po_box,
  //         island, city,
  //         payment_frequency,
  //         owner_id, resident_id } = paymentInfo?.property?.;

  // const { user_id, payment_amount, payment_invoice,
  //         payment_status, initial_deposit, desc,
  //         paid_date, next_pay_date } = paymentInfo;

  const initialDeposit = (depositExpected) => {
    let deposit = parseInt(depositExpected)
    deposit = deposit ? deposit : '0'
    return deposit;
  }

  const paymentTypeSubscription = () => (
    <button type='button' className='not-button' onClick={() => setShowSubscriptionPlans(!showSubscriptionPlans)}>
      <div className={`subscriber-plan ${paymentInfo?.subscription_plan === 'premium' && 'premium'}`} style={{minWidth: '400px', maxWidth: '400px', boxShadow: `${!paymentInfo?.active && '2px 6px 8px 5px rgb(220 106 76 / 39%)'}`}}>
        <h3>{t(paymentInfo?.subscription_plan)}</h3>
        <div className='mb-1'>${numberWithCommas(paymentInfo?.payment_amount)} / <b>{paymentInfo?.months_active === '1' ? t('month') : t('week')}</b></div>
        <div className='flex-col'>
          <div className='flex-row justify-flex-center mb-1 text-bold'><span>{paymentInfo?.active ? 'Active' : 'Expired'}</span></div>
          <div className='flex-row'><span className='text-caps text-bold'>{t('f_invoiceNumb')}</span><span>: {paymentInfo?.payment_invoice || 'None'}</span></div>
          <div className='flex-row'><span className='text-caps text-bold'>{t('f_activateDate')}</span><span>: {paymentInfo?.active_date.split('T')[0]}</span></div>
          <div className='flex-row'><span className='text-caps text-bold'>{t('f_expiryDate')}</span><span>: {paymentInfo?.expiry_date.split('T')[0]}</span></div>
        </div>
      </div>
    </button>
  );

  const paymentTypeRented = () => (
    <button type='button' className='not-button' onClick={() => handlePaymentSelect(paymentInfo, 'rented')}>
    <div>
      <h3 className='text-camel'>{`
        ${paymentInfo?.property?.property_group?.street_address || paymentInfo?.property?.street_address}, 
        ${paymentInfo?.property?.property_group?.island || paymentInfo?.property?.island}, 
        ${paymentInfo?.property?.property_group?.settlement || paymentInfo?.property?.settlement}
        `}
      </h3>
      <div className='p-05 text-camel transact-table'>
        <div>
          <span>{t('f_propertyType')}: </span>
          <span>{paymentInfo?.property?.property_type}</span>
        </div>
        <div>
          <span>{t('f_initialDeposit')}:</span>
          <span>${numberWithCommas(initialDeposit(paymentInfo?.initial_deposit))}</span>
        </div>
        <div>
          <span>{t('f_monthlyFee')}:</span>
          <span>${numberWithCommas(parseInt(paymentInfo?.property?.fee))}</span>
        </div>
        <div>
          <span>{t('f_totalFee')}: </span>
          <span>${numberWithCommas(paymentInfo?.payment_amount)}</span>
        </div>
        <div>
          <span>{t('f_frequency')}: </span>
          <span>{paymentInfo?.property?.payment_frequency}</span>
        </div>
        <div>
          <span>{t('f_paymentStatus')}: </span>
          <span className={`${paymentInfo?.payment_status ? 'text-green' : 'text-red'} text-bold`}>{paymentInfo?.payment_status ? 'Paid' : 'Unpaid'}</span>
        </div>
        <div>
          <span>{t('f_invoiceNumb')}: </span>
          <span>{paymentInfo?.payment_invoice || 'NONE'}</span>
        </div>
        <div>
          <span>{t('f_desc')}: </span>
          <span>{paymentInfo?.desc}</span>
        </div>
        <div>
          <span>{t('f_datePaid')}: </span>
          <span>{paymentInfo?.paid_date || 'NONE'}</span>
        </div>
        <div>
          <span>{t('f_nextPayDate')}: </span>
          <span>{paymentInfo?.next_pay_date || 'NONE'}</span>
        </div>
        {/* <div><span>Recurring:</span> Yes</div> */}
        {!paymentInfo.payment_status && (
          <div className='mt-05 no-pointer-event div-payment-btn'>
            <div className='payment-box-btn' type='button'>{t('f_tapToPay')}</div>
          </div>
        )}
      </div>
    </div>
    </button>
  );

  return (
    <div>
      {paymentType === 'subscription' && (
        <div className='payment-box pt-1' style={{backgroundColor: 'transparent', border: 'none'}}>
          {paymentTypeSubscription()}
        </div>
      )}
      {paymentType === 'rented' && (
        <div className='payment-box pt-1'>
          {paymentTypeRented()}
        </div>
      )}
    </div>
  );
};

PaymentSettingDisplay.propTypes = {
  user: propTypes.instanceOf(Object),
  property: propTypes.instanceOf(Object),
  paymentInfo: propTypes.instanceOf(Object),
  showSubscriptionPlans: propTypes.bool,
  setShowSubscriptionPlans: propTypes.func,
  handlePaymentSelect: propTypes.func,
};

export default PaymentSettingDisplay;
