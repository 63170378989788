/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useRef } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import propTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { newPropertyGroup, fetchPropertyGroups,
         updatePropertyGroup, destroyPropertyGroup,
       } from '../../misc/apiRequests';
import '../../../assets/css/property.css';
import ImageGallery from '../imageGallery';
import CoverPhoto from './coverPhoto';
import { islands } from '../../misc/jsonData/islandData';
import Swal from 'sweetalert2';
import OpenMapLeaf from '../../misc/openStreetMap';

const PropertyGroup = ({ propertyGroups, setPropertyGroups,
                         handleMessagesModal, handleImageModal,
                         handleModal, handleLoader }) => {
  const { t } = useTranslation();

  // Property Groups
  const [images, setImages] = useState([]);
  const [savedImages, setSavedImages] = useState([]);
  const [removeImages, setRemoveImages] = useState([]);

  const [showGroupInfo, setShowGroupInfo] = useState(true);
  // const [propertyGroups, setPropertyGroups] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState('');
  const [groupName, setGroupName] = useState('');
  const [streetAddress, setStreetAddress] = useState('');
  const [poBox, setPoBox] = useState('');
  const [island, setIsland] = useState('');
  const [city, setCity] = useState('');
  const [latitude, setLatitude] = useState('');
  const [longitude, setLongitude] = useState('');

  const [settlement, setSettlement] = useState('');
  const [desc, setDesc] = useState('');
  const [moveInSpecial, setMoveInSpecial] = useState('');
  const [highlights, setHighlights] = useState([]);
  const [disclaimer, setDisclaimer] = useState('');
  const [coverPhotoIndex, setCoverPhotoIndex] = useState(0);
  const [electricity, setElectricity] = useState(false);
  const [water, setWater] = useState(false);
  const [internet, setInternet] = useState(false);

  const uploadsInputRef = useRef(null);
  let { id } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();

  
  useEffect(() => {
    if (searchParams.get('group_name') !== undefined) {
      setSelectedGroup(searchParams.get('group_id'));
    }
  }, [searchParams])

  // Populate Islands and Settlements
  const populateIslands = () => islands.map((data, index) => (
    <option className='size-1' key={index} value={data.name}>{`${data.name}, Bahamas`}</option>
  ));
  const populateSettlements = () => islands.filter(x => x.name === island)[0]?.settlements.map((data, index) => (
    <option className='size-1' key={index + data} value={data}>{data}</option>
  ));

  // Handle Select Property Group
  useEffect(() => {
    if(propertyGroups?.length > 0 && selectedGroup) {
      const propertyGroupID = parseInt(selectedGroup, 10);
      const filteredGroup = propertyGroups.filter(g => g.id === propertyGroupID)[0];

      setGroupName(filteredGroup.group_name);
      setStreetAddress(filteredGroup.street_address);
      setPoBox(filteredGroup.po_box || '');
      setIsland(filteredGroup.island);
      // setCity(filteredGroup.city);
      setSettlement(filteredGroup.settlement || '');
      setLatitude(filteredGroup.latitude || '');
      setLongitude(filteredGroup.longitude || '');
      setSavedImages(filteredGroup.images)
      setCoverPhotoIndex(filteredGroup.cover_photo_index || 0)

      setDesc(filteredGroup.desc || '')
      setMoveInSpecial(filteredGroup.move_in_special || '')
      setDisclaimer(filteredGroup.disclaimer || '')
      setHighlights(filteredGroup.highlights || [])
    }
  }, [propertyGroups, selectedGroup]);

  const handleClearForm = e => {
    e.preventDefault();
    const fileUploadElem = uploadsInputRef.current;

    setGroupName('');
    setStreetAddress('');
    setPoBox('');
    setIsland('');
    // setCity('');
    setSettlement('');
    setLatitude('');
    setLongitude('');
    setDesc('')
    setMoveInSpecial('')
    setDisclaimer('')
    // setHighlights([])

    fileUploadElem.value = '';
    setSavedImages([]);
    setImages([]);
    setCoverPhotoIndex(0);
  };

  const handleDeleteGroup = async e => {
    e.preventDefault();
    const result = await Swal.fire({
      title: 'Remove Property Group?',
      icon: 'question',
      // toast: true,
      text: 'Are you sure you want to permanently remove this property group?',
      showConfirmButton: true,
      confirmButtonColor: '#a64a3d',
      showDenyButton: false,
      showCancelButton: true,
    })
    
    if (result.isConfirmed) {
      const result = await Swal.fire({
        title: 'Remove Property Group and Related Units?',
        icon: 'warning',
        // toast: true,
        html: "Are you certain? <br/><span style = 'color: darkred;'/>All grouped units will be removed as well!</span>",
        showConfirmButton: true,
        confirmButtonColor: '#a64a3d',
        showDenyButton: false,
        showCancelButton: true,
        reverseButtons: true,
      })

      if (result.isConfirmed) {
        handleLoader(true);
        destroyPropertyGroup(selectedGroup)
        .then(response => {
          if (response.success) {
            handleMessagesModal(response.message);
            setPropertyGroups(response.records);
            setSelectedGroup('');
          }
          if (!response.success) {
            // handleModal(response.errors);
            Swal.fire({
              position: 'middle',
              icon: 'error',
              title: Array.isArray(response.errors) ? response.errors.join("\n") : response.errors,
              showConfirmButton: false,
              timer: 2000
            })
          }
          handleLoader(false);
        });
      }
    }

    return null;
  };

  const handleSubmitGroup = e => {
    e.preventDefault();

    const propertyGroup = new FormData();
    propertyGroup.append('property_group[group_name]', groupName);
    propertyGroup.append('property_group[street_address]', streetAddress.trim());
    propertyGroup.append('property_group[po_box]', poBox);
    propertyGroup.append('property_group[island]', island.trim());
    // propertyGroup.append('property_group[city]', city.trim());
    propertyGroup.append('property_group[settlement]', settlement.trim());
    propertyGroup.append('property_group[latitude]', latitude);
    propertyGroup.append('property_group[longitude]', longitude);

    propertyGroup.append('property_group[desc]', desc);
    propertyGroup.append('property_group[move_in_special]', moveInSpecial);
    propertyGroup.append('property_group[disclaimer]', disclaimer);

    propertyGroup.append('property_group[cover_photo_index]', coverPhotoIndex);
    removeImages.forEach((x) => propertyGroup.append("property_group[remove_images][]", x))
    images.forEach((x) => propertyGroup.append("property_group[images][]", x))

    highlights.forEach((x) => propertyGroup.append("property_group[highlights][]", x))

    handleLoader(true);
    if(selectedGroup) {
      updatePropertyGroup(selectedGroup, propertyGroup)
      .then(response => {
        if (response.success) { handleMessagesModal(response.message); setPropertyGroups(response.records); handleClearImages(); }
        if (!response.success) {
          // handleModal(response.errors);
          Swal.fire({
            position: 'middle',
            icon: 'error',
            title: Array.isArray(response.errors) ? response.errors.join("\n") : response.errors,
            showConfirmButton: false,
            timer: 2000
          })
        }
        handleLoader(false);
      });
    } else {
      newPropertyGroup(propertyGroup)
        .then(response => {
          if (response.success) { handleMessagesModal(response.message); setPropertyGroups(response.records); }
          if (!response.success) {
            // handleModal(response.errors);
            Swal.fire({
              position: 'middle',
              icon: 'error',
              title: Array.isArray(response.errors) ? response.errors.join("\n") : response.errors,
              showConfirmButton: false,
              timer: 2000
            })
          }
          handleLoader(false);
        });
    }

    return null;
  };

  // Ensures that the property images uploaded doesn't go too far over 1 megabyte
  const handleCheckFileSize = e => {
    const elem = e.target;
    let overlimit = false;
    const errorList = [];
    Array.from(elem.files).forEach((x, index) => {
      if (elem.files[index].size > 3048576) {
        console.log(elem.files[index]);
        errorList.push(`${x.name} - ${t('fileBig')}`);
        overlimit = true;
      } else { 
        const imagesList = images;
        imagesList.push(elem.files[index]);
        setImages(imagesList);
      }
    })

    if (overlimit) {
      elem.value = '';
      setImages([]);
      handleMessagesModal(errorList);
    }
  };

  const handleCoverPhotoSelect = (i) => {
    // e.preventDefault();
    setCoverPhotoIndex(i)
  }

  const handleImageRemoval = (e, id) => {
    e.preventDefault();

    const idFound = removeImages.find(x => x === id);
    let newList = [];

    if (idFound) {
      newList = removeImages.filter(x => x !== id);
    } else {
      newList = removeImages;
      newList.push(id);
    }
    setRemoveImages(newList);
  };

  const handleClearImages = () => {
    const uploadsInputElem = uploadsInputRef.current;

    uploadsInputElem.value = '';
    setImages([]);
  }

  const populateImages = () => savedImages.map(img => (
    <ImageGallery key={img.id} imgInfo={img} handleImageModal={handleImageModal} handleImageRemoval={handleImageRemoval} />
  ));

  const populateGroups = () => propertyGroups.map(data => (
    <option key={data.id} value={data.id}>{data.group_name}</option>
  ));

  const propertyGroupForm = (
    <form className="edit-form" onSubmit={handleSubmitGroup} encType="multipart/form-data">
    {/* <button className='mb-1' type='button' onClick={() => setShowGroupInfo(!showGroupInfo)}><h3>Hide Property Groups</h3></button> */}
    <h3 className="pb-1">{t('propertyGroup')}</h3>
    <span className='form-sub-text pb-1 text-grey'>{t('propertyGroupDesc')}</span>

    <span className='form-sub-text'>{t('propertyGroupSelect')}</span>
    <select name="property_group" id="property_group" onChange={e => setSelectedGroup(e.target.value)}>
      <option value=""></option>
      {populateGroups()}
    </select>
    <h4 className="pb-1">{selectedGroup ? t('propertyGroupEdit') : t('propertyGroupCreate')}</h4>
    <span className='form-sub-text'>{t('propertyGroupName')}</span>
    <input
      type="text"
      value={groupName}
      onChange={e => setGroupName(e.target.value)}
      minLength="3"
      required
    />
    <span className='form-sub-text'>{t('f_island')}</span>
    <select className='' required name="island" id="by_island" value={island} onChange={e => {setIsland(e.target.value);}}>
      <option value={null}></option>
      {populateIslands()}
    </select>
    <span className='form-sub-text'>{t('f_settlement')}</span>
    <select className='' required name="settlement" id="by_settlement" value={settlement} onChange={e => setSettlement(e.target.value)}>
      <option value={null}></option>
      {populateSettlements()}
    </select>
    <span className='form-sub-text'>{t('f_streetAddress')}</span>
    <input
      type="text"
      value={streetAddress}
      onChange={e => setStreetAddress(e.target.value)}
      minLength="3"
      required
    />
    <span className='form-sub-text'>{t('f_PoBox')}</span>
    <input
      type="text"
      value={poBox}
      onChange={e => setPoBox(e.target.value)}
      minLength="3"
      required
    />
    <OpenMapLeaf formLatitude={latitude} formLongitude={longitude} setFormLatitude={setLatitude} setFormLongitude={setLongitude} showMap={false} showLoc={true}/>
    {/* <span className='form-sub-text mt-1 mb-05'>
      <a target='_blank' rel='noreferrer' href='https://support.google.com/maps/answer/18539?hl=en&co=GENIE.Platform%3DDesktop#:~:text=of%20a%20place-,On%20your%20computer%2C%20open%20Google%20Maps.,decimal%20format%20at%20the%20top.'>Googlemaps co-ordinates Help?</a>
    </span> */}
    <span className='form-sub-text'>{t('f_latitude')}</span>
    <input
      type="text"
      value={latitude}
      onChange={e => setLatitude(e.target.value)}
    />
    <span className='form-sub-text'>{t('f_longitude')}</span>
    <input
      type="text"
      value={longitude}
      onChange={e => setLongitude(e.target.value)}
    />
    <span className='form-sub-text'>{t('f_desc')}</span>
    <textarea
      value={desc}
      onChange={e => setDesc(e.target.value)}
    />
    <span className='form-sub-text'>{t('f_moveInSpecial')}</span>
    <textarea
      value={moveInSpecial}
      onChange={e => setMoveInSpecial(e.target.value)}
    />
    <span className='form-sub-text'>{t('f_disclaimer')}</span>
    <textarea
      value={disclaimer}
      onChange={e => setDisclaimer(e.target.value)}
    />
    {/* <span className='form-sub-text'>{t('f_highlights')}</span>
    <input
      type="text"
      value={highlights}
      onChange={e => setHighlights(e.target.value)}
    /> */}

    <h4>{t('f_propertyPhoto')}</h4>
    <span className='form-sub-text text-grey'>* {t('f_propertyPhotoDesc')}</span>
    <span className='form-sub-text text-grey'>* {t('f_propertyPhotoDesc2')}</span>
    <div className='flex-row mb-1'>{populateImages()}</div>
    <input
      type="file"
      multiple
      ref={uploadsInputRef}
      alt='Property photo uploads'
      name="photo_uploads"
      accept="image/png, image/jpeg, image/jpg"
      // value={photoUpload}
      onChange={e => handleCheckFileSize(e)}
    />
    <button type='button' onClick={() => handleClearImages()} >{t('f_clearImage')}</button>

    <span className='form-sub-text mt-1'>{t('f_selectPhoto')}</span>
    <span className='form-sub-text text-grey'>* {t('f_selectPhotoDesc')}</span>
    <CoverPhoto
      allImages={savedImages}
      coverPhotoIndex={coverPhotoIndex}
      handleCoverPhotoSelect={handleCoverPhotoSelect}
      imageType={'EditGroup'}
    />
  
    <div className='flex-row mt-1'>
      {!selectedGroup && <button className="mr-auto" style={{backgroundColor: '#a23131'}} type="button" onClick={(e) => handleClearForm(e)}>{t('f_clear')}</button>}                                                                                                                                                                                        
      {selectedGroup && <button className="mr-auto" style={{backgroundColor: '#a23131'}} type="button" onClick={(e) => handleDeleteGroup(e)}>{t('propertyGroupRemove')}</button>}                                                                                                                                                                                        
      <button className="ml-auto" type="submit">{selectedGroup ? t('propertyGroupUpdate') : t('propertyGroupNew')}</button>
    </div>
    </form>
  )

  return (
    <div>
      {!showGroupInfo && <button type='button' onClick={() => setShowGroupInfo(!showGroupInfo)} className='modal-btn'><h3>{t('propertyGroupShow')}</h3></button>}
      {showGroupInfo && propertyGroupForm}
    </div>
    );
};

PropertyGroup.propTypes = {
  propertyGroups: propTypes.instanceOf(Object),
  setPropertyGroups: propTypes.func.isRequired,
  handleMessagesModal: propTypes.func.isRequired,
  handleModal: propTypes.func.isRequired,
  handleLoader: propTypes.func.isRequired,
  handleImageModal: propTypes.func.isRequired
};

export default PropertyGroup;
