/* eslint-disable no-unused-vars */

import React, { useState, useEffect } from 'react';
import propTypes from 'prop-types';
import PageIndex from '../../../presentational/pageIndex';
import PaymentSettingDisplay from '../../../presentational/properties/payments/paymentSettingDisplay';
import PaymentModal from '../../../presentational/properties/payments/paymentModal';
import { useTranslation } from 'react-i18next';
import { fetchProperties, fetchResidentTransactions,
         fetchCurrentSubscription, newSubscription, fetchConfiguration } from '../../../misc/apiRequests';
import Exit from '../../../../assets/images/exit.svg';
import Swal from 'sweetalert2';
// import { propertiesData, transactionsData, subscriptionData } from '../../../misc/jsonData/propertyListingData'

const PropertyPayments = ({ user, handleMessagesModal, handleLoader, handleModal }) => {
  const { t } = useTranslation();

  const [recentSubscription, setRecentSubscription] = useState(null);
  const [showSubscriptionPlans, setShowSubscriptionPlans] = useState(false);

  const [rentedProperties, setRentedProperties] = useState([]);
  const [pageNumbRental, setPageNumbRental] = useState(1);
  const [maxPagesRental, setMaxPagesRental] = useState(1);
  const [perPageRental, setPerPageRental] = useState(5);

  const [selectedPayment, setSelectedPayment] = useState(null);
  const [paymentModalType, setPaymentModalType] = useState('owned');

  const [basicWeeklyCost, setBasicWeeklyCost] = useState(0.0);
  const [basicMonthlyCost, setBasicMonthlyCost] = useState(0.0);
  const [premiumWeeklyCost, setPremiumWeeklyCost] = useState(0.0);
  const [premiumMonthlyCost, setPremiumMonthlyCost] = useState(0.0);
  const [businessWeeklyCost, setBusinessWeeklyCost] = useState(0.0);
  const [businessMonthlyCost, setBusinessMonthlyCost] = useState(0.0);
  const [maxPropertiesBasic, setMaxPropertiesBasic] = useState(1);
  const [maxPropertiesPremium, setMaxPropertiesPremium] = useState(3);

  // Handle Subscription plan
  const handleSubscriptionPlan = (planSelected, timePeriod = 'week') => {
    const date = new Date();
    let expiry = new Date();
    if (timePeriod === 'week') expiry = new Date(expiry.setDate(date.getDate()+7));
    if (timePeriod === 'month') expiry = new Date(expiry.setMonth(date.getMonth()+1));
    let amount = '';
    switch (planSelected) {
      case 'basic':
        if (timePeriod === 'week') amount = basicWeeklyCost;
        if (timePeriod === 'month') amount = basicMonthlyCost;
        break;
      case 'premium':
        if (timePeriod === 'week') amount = premiumWeeklyCost;
        if (timePeriod === 'month') amount = premiumMonthlyCost;
        break;
      case 'business':
        if (timePeriod === 'week') amount = businessWeeklyCost;
        if (timePeriod === 'month') amount = businessMonthlyCost;
        break;
      default:
        amount = basicWeeklyCost;
    }
    const plan = { subscription: {subscription_plan: planSelected,
                                  months_active: timePeriod === 'month' ? 1 : 0,
                                  payment_invoice: null, payment_amount: amount,
                                  active_date: date.toJSON(),
                                  expiry_date: expiry.toJSON(),
                                  active: true} };

    handleLoader(true);
    newSubscription(plan)
      .then(response => {
        if (response.success) { handleMessagesModal(response.message); setRecentSubscription(response.record); }
        if (!response.success) {
          // handleModal(response.errors);
          Swal.fire({
            position: 'middle',
            icon: 'error',
            title: Array.isArray(response.errors) ? response.errors.join("\n") : response.errors,
            showConfirmButton: false,
            timer: 2000
          })
        }
        handleLoader(false);
        setShowSubscriptionPlans(false);
      });
  };

  // Handles selection of property when property is clicked
  const handlePaymentSelect = (payment, paymentType = 'owned') => {
    setPaymentModalType(paymentType);
    setSelectedPayment(payment);
  };

  const handlePaymentModal = () => {
    setSelectedPayment(null);
  };

  // Populate all payment settings for rented properties as a tenant
  const populateSubscription = () => (
    <PaymentSettingDisplay
      key={recentSubscription.id}
      paymentInfo={recentSubscription}
      user={user}
      showSubscriptionPlans={showSubscriptionPlans}
      setShowSubscriptionPlans={setShowSubscriptionPlans}
      paymentType="subscription"
    />
  );

  // Populate all payment settings for rented properties as a tenant
  const populateTransactions = () => rentedProperties.map(transaction => (
    <PaymentSettingDisplay
      key={transaction.id}
      paymentInfo={transaction}
      user={user}
      handlePaymentSelect={handlePaymentSelect}
      paymentType="rented"
    />
  ));

  const subscriptionModal = (
    <div className="modal">
      <div className="modal-content" style={{maxWidth: '1000px', maxHeight: '660px', minHeight: '620px'}}>
        <div className="container-md">
          <div className="messages-container">
            {/* <h2 className='text-white' style={{backgroundColor: '#6b256b'}}>Subscription Plans</h2> */}
            <h2 className='mb-1'>{t('choose_plan')}</h2>
            <div className='flex-row justify-flex-center'>
              <div className='subscriber-plan mr-1'>
                <h3>{t('basic')}</h3>
                <div>${basicWeeklyCost.toFixed(2)} /<b>{t('week')}</b></div>
                <button type='button' className='mt-1 mb-1' onClick={() => handleSubscriptionPlan('basic', 'week')}>{t('choose')}</button>
                <ul>
                  <li>{t('allowed_to_list')} {maxPropertiesBasic}</li>
                </ul>
              </div>
              <div className='subscriber-plan'>
                <h3>{t('basic')}</h3>
                <div>${basicMonthlyCost.toFixed(2)} /<b>{t('month')}</b></div>
                <button type='button' className='mt-1 mb-1' onClick={() => handleSubscriptionPlan('basic', 'month')}>{t('choose')}</button>
                <ul>
                  <li>{t('allowed_to_list')} {maxPropertiesBasic}</li>
                </ul>
              </div>
            </div>
            <div className='subscriber-divider mt-2 mb-2'/>
            <div className='flex-row justify-flex-center'>
              <div className='subscriber-plan mr-1 premium'>
                <h3>{t('premium')}</h3>
                <div>${premiumWeeklyCost.toFixed(2)} / <b>{t('week')}</b></div>
                <button type='button' className='mt-1 mb-1'  onClick={() => handleSubscriptionPlan('premium', 'week')}>{t('choose')}</button>
                <ul>
                  <li>{t('allowed_to_list')} {maxPropertiesPremium}</li>
                  {/* <li>{t('can_accept_tenants')}</li>
                  <li>{t('can_accept_payments')}</li> */}
                </ul>
              </div>
              <div className='subscriber-plan premium'>
                <h3>{t('premium')}</h3>
                <div>${premiumMonthlyCost.toFixed(2)} / <b>{t('month')}</b></div>
                <button type='button' className='mt-1 mb-1'  onClick={() => handleSubscriptionPlan('premium', 'month')}>{t('choose')}</button>
                <ul>
                  <li>{t('allowed_to_list')} {maxPropertiesPremium}</li>
                  {/* <li>{t('can_accept_tenants')}</li>
                  <li>{t('can_accept_payments')}</li> */}
                </ul>
              </div>
            </div>
            <div className='subscriber-divider mt-2 mb-2'/>
            <div className='flex-row justify-flex-center'>
              <div className='subscriber-plan mr-1 business'>
                <h3>{t('business')}</h3>
                <div>${businessWeeklyCost.toFixed(2)} / <b>{t('week')}</b></div>
                <button type='button' className='mt-1 mb-1'  onClick={() => handleSubscriptionPlan('business', 'week')}>{t('choose')}</button>
                <ul>
                  <li>{t('allowed_to_list_unlimited')}</li>
                  {/* <li>{t('can_accept_tenants')}</li>
                  <li>{t('can_accept_payments')}</li> */}
                </ul>
              </div>
              <div className='subscriber-plan business'>
                <h3>{t('business')}</h3>
                <div>${businessMonthlyCost.toFixed(2)} / <b>{t('month')}</b></div>
                <button type='button' className='mt-1 mb-1'  onClick={() => handleSubscriptionPlan('business', 'month')}>{t('choose')}</button>
                <ul>
                  <li>{t('allowed_to_list_unlimited')}</li>
                  {/* <li>{t('can_accept_tenants')}</li>
                  <li>{t('can_accept_payments')}</li> */}
                </ul>
              </div>
            </div>
          </div>
        </div>
        <button type="button" className="modal-exit" onClick={() => setShowSubscriptionPlans(false)}><img src={Exit} alt='exit icon' /></button>
      </div>
    </div>
  );

  // Grab API Configuration
  useEffect(() => {
    handleLoader(true);
    fetchConfiguration()
      .then(response => {
        if (response.success) {
          setMaxPropertiesBasic(response.record.max_properties);
          setMaxPropertiesPremium(response.record.max_properties_premium);
          setBasicWeeklyCost(Number(response.record.basic_subscription_weekly_cost));
          setBasicMonthlyCost(Number(response.record.basic_subscription_monthly_cost));
          setPremiumWeeklyCost(Number(response.record.premium_subscription_weekly_cost));
          setPremiumMonthlyCost(Number(response.record.premium_subscription_monthly_cost));
          setBusinessWeeklyCost(Number(response.record.business_subscription_weekly_cost));
          setBusinessMonthlyCost(Number(response.record.business_subscription_monthly_cost));
        }
        if (!response.success) {
          // handleModal(response.errors);
          Swal.fire({
            position: 'top-end',
            toast: true,
            timerProgressBar: true,
            icon: 'error',
            title: Array.isArray(response.errors) ? response.errors.join("\n") : response.errors,
            showConfirmButton: false,
            timer: 2000
          })
        }
        handleLoader(false);
      });
  }, [handleLoader, handleModal]);


  // Grab all current residential property transactions by current user
  useEffect(() => {
    handleLoader(true);
    const record = { per_page: perPageRental, page: pageNumbRental, };
    fetchResidentTransactions(record.per_page, record.page)
      .then(response => {
        if (response.success) {
          setRentedProperties(response.records);
          setMaxPagesRental(response.records_params.max_page || 1);
        }
        if (!response.success) handleModal(response.errors);
        handleLoader(false);
      });
  }, [handleLoader, handleModal, pageNumbRental, perPageRental]);

  // Grab current subscription
  useEffect(() => {
    handleLoader(true);
    fetchCurrentSubscription()
      .then(response => {
        if (response.success) {
          setRecentSubscription(response.subscription);
          !response.subscription && setShowSubscriptionPlans(true)
        }
        if (!response.success) handleModal(response.errors);
        handleLoader(false);
      });
  }, [handleLoader, handleModal]);

    // Grab all FAKE DATA for properties posted by current user
    // useEffect(() => {
    //     handleLoader(true);
    //     setRecentSubscription(subscriptionData);
    //     const ownedProperties = propertiesData.filter(x => x.owner_id === user.id)
    //     const rentedProperties = transactionsData.filter(x => x.user_id === user.id)

    //     setProperties(ownedProperties);
    //     setMaxPagesOwned(1);
    //     setRentedProperties(rentedProperties);
    //     setMaxPagesRental(1);

    //     handleLoader(false);
    // }, [handleLoader, user.id]);

  return (
    <div className='acct-menu-container'>
      <h1>{t('f_payment')}</h1>
      {user?.roles?.includes('renter') && <div className='mb-1'>
        <h3>{t('recent_sub')}</h3>
        <div className='flex-row'>{recentSubscription && populateSubscription()}</div>
        {showSubscriptionPlans && subscriptionModal}
        <button className='btn mt-1' onClick={() => setShowSubscriptionPlans(true)}>
          Purchase Subscription Plan
        </button>
      </div>}

      {user?.roles?.includes('citizen') && (
        <div className='mb-1'>
          <h3>{t('my_home_rental')}</h3>
          {/* <button type='button'>Search by address</button> */}
          {/* <form className='flex-row flex-start pb-1' onSubmit={handleSearchRental}>
            <input type='text'
              className='search-btn'
              value={searchRental}
              onChange={e => setSearchRental(e.target.value)}
              minLength="4"
              required
            />
            <button type="submit" className='button-search'>{t('search')}</button>
          </form> */}
          <div className='flex-row'>{populateTransactions()}</div>
          {maxPagesRental > 1 && (
            <div className='text-center'>
              <PageIndex setPage={setPageNumbRental} setPerPage={setPerPageRental} page={pageNumbRental} perPage={perPageRental} maxPages={maxPagesRental} />
            </div>
          )}
        </div>
      )}

      {selectedPayment && <PaymentModal info={selectedPayment} handleLoader={handleLoader} handleModal={handleModal} handlePaymentModal={handlePaymentModal} />}
    </div>
  );
};

PropertyPayments.propTypes = {
  user: propTypes.instanceOf(Object),
  handleMessagesModal: propTypes.func.isRequired,
  handleLoader: propTypes.func.isRequired,
  handleModal: propTypes.func.isRequired
};

export default PropertyPayments;
