import React, { useEffect, useState } from 'react';
import propTypes from 'prop-types';
import { forgotPassword } from '../../misc/apiRequests';
import { useTranslation } from 'react-i18next';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';

const ForgotPassword = ({ handleModal, handleLoader }) => {
  const { t } = useTranslation();

  const [emailSent, setEmailSent] = useState(false);
  const [email, setEmail] = useState('');

  const navigate = useNavigate()

  const handleSubmit = e => {
    e.preventDefault();
    const emailTrim = email.trim();

    handleLoader(true);
    forgotPassword(emailTrim)
      .then(response => {
        if (response.success) { 
          setEmailSent(true);
          Swal.fire({
            position: 'middle',
            icon: 'success',
            title: 'Reset Request Email Successful',
            text: response.message,
            showConfirmButton: true,
            // timer: 2000
          })
        }
        if (!response.success) {
          // handleModal(response.errors);
          Swal.fire({
            position: 'middle',
            icon: 'error',
            title: Array.isArray(response.errors) ? response.errors.join("\n") : response.errors,
            showConfirmButton: false,
            timer: 2500
          })
        }
        handleLoader(false);
      });
  };

  useEffect(() => {
    if (emailSent) navigate('/login')
  }, [emailSent, navigate])

  return (
      <div id="LoginPage" className="bg-main">
        <div className='forgotPassBG'>
        <span role="img" aria-label="https://unsplash.com/photos/uHKcQDWRCq8?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText"/>  
        <div className="container-md">
          <form className="login-form" onSubmit={handleSubmit}>
            <h2 className="text-center mb-1">{t('f_forgotPass')}</h2>
            <span className="text-center mb-1">{t('f_passwordReset')}</span>
            <span className='form-sub-text'>{t('f_email')}</span>
            <input
              type="email"
              value={email}
              onChange={e => setEmail(e.target.value)}
              minLength="3"
              required
            />
            <button type="submit">{t('f_sendEmail')}</button>
          </form>
        </div>
        </div>
      </div>
    )
};

ForgotPassword.propTypes = {
  handleModal: propTypes.func.isRequired,
  handleLoader: propTypes.func.isRequired,
};

export default ForgotPassword;