import { useEffect, useState, useRef } from 'react';
import leaf from 'leaflet';

const OpenMapLeaf = ({ formLatitude, formLongitude, setFormLatitude, setFormLongitude, showMap = false, showLoc = false }) => {
    // Used for showMap render
    const openmapURL = `https://www.openstreetmap.org/?mlat=${formLatitude}&mlon=${formLongitude}#map=17/${formLatitude}/${formLongitude}`;
    // console.log(form, openmapURL);
  
    const containerStyle = {
      width: '100%',
      height: '400px',
      marginBottom: '20px', // Add margin at the bottom
      zIndex: '1',
    };
  
    const defaultCenter = {center: [formLatitude || 25.0343, formLongitude || -77.3963], zoom: 14}; // Latitude , Longitude
  
    const [currentPosition, setCurrentPosition] = useState(defaultCenter) // []
    const [latitude, setLatitude] = useState('');
    const [longitude, setLongitude] = useState('');
  
    const mapRef = useRef(null);
    // const markerRef = useRef(null);
  
    // Navigator Gelocation getCurrentPosition() callbacks, Success and Error
    const success = (pos) => {
      const crd = pos.coords;
    
      // console.log("Your current position is:");
      // console.log(`Latitude : ${crd.latitude}`);
      // console.log(`Longitude: ${crd.longitude}`);
      // console.log(`More or less ${crd.accuracy} meters.`);
  
      setCurrentPosition({center: [crd.latitude, crd.longitude], zoom: 14})
    }
    
    const error = (err) => {
      console.warn(`ERROR(${err.code}): ${err.message}`);
    }
  
    // Generate Leaflet map using OpenStreetMap
    useEffect(() => {
      // Navigator Geolocation object options
      const options = {
        enableHighAccuracy: true,
        timeout: 25000,
        maximumAge: 0,
      };
  
      // Generate a map with default co-ordinates on component load
      if (!showMap && mapRef.current == null) {
        mapRef.current = leaf.map('Map', currentPosition);
        leaf.tileLayer('https://tile.openstreetmap.org/{z}/{x}/{y}.png', {
          maxZoom: 19,
          attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'
        }).addTo(mapRef.current);
  
  
        if (!showLoc) {
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(success, error, options)
                } else {
                console.log("Geolocation not supported");
            }
        }
      }
    }, [currentPosition, showLoc, showMap])
  
  
    useEffect(() => {
      // Set map default co-ordinates to current user position of Navigator geolocation was allowed
      if (!showMap && !showLoc) mapRef.current.setView(currentPosition.center, 14);

      if(!showMap && formLatitude && showLoc) {
        var popup = leaf.popup();
        const latLng = { lat: formLatitude, lng: formLongitude }
        // console.log(latLng, latLng.toString());
        popup
            .setLatLng(latLng)
            .setContent(`LatLng(${latLng.lat}, ${latLng.lng})`)
            .openOn(mapRef.current);
      }
    }, [currentPosition, formLatitude, formLongitude, showMap, showLoc])
  
    useEffect(() => {
      const onMapClick = (e) => {
        var popup = leaf.popup();
        // console.log(e.latlng, e.latlng.toString());
        setLatitude(e.latlng.lat);
        setLongitude(e.latlng.lng);
  
        popup
            .setLatLng(e.latlng)
            .setContent(e.latlng.toString())
            .openOn(mapRef.current);
      }
  
      if (mapRef.current) {
        mapRef.current.on('click', onMapClick);
      }
    }, [mapRef])
  
    useEffect(() => {
      setFormLatitude(latitude);
    }, [latitude, setFormLatitude])
  
    useEffect(() => {
      setFormLongitude(longitude);
    }, [longitude, setFormLongitude])
  
    // Return an OpenMap URL if showMap prop is true otherwise return map widget
    return (
      showMap ? 
      <a href={openmapURL} target='_blank' rel='noreferrer'>(Click here for Map Location)</a>
      : 
      <div id='Map' style={containerStyle}/>
    )
  }

  export default OpenMapLeaf;
